import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <p>Manna Hot Bagels - All Rights Reserved. 2024</p>
    </footer>
  );
};

export default Footer;